import React, { useCallback, useState } from 'react';
import {
  Filter, Datagrid, SelectInput, TextField, TextInput, FunctionField,
} from 'react-admin';

import moment from 'moment';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import FormatValueField from '../../components/fields/FormatValueField';
import RestrictedList from '../../components/admin/RestrictedList';
import TelemedicDataSource from '../../components/TelemedicDataSource';
import useTelemedicService from '../../hooks/useTelemedicService';
import useInterval from '../../hooks/useInterval';
import {
  formatDisplayDate, formatDisplayInterval, formatQuantity,
} from '../../utils/formatters';
import styles from './TerminalList.module.css';

const OnlineIndicator = ({ show }) => (show ? <div className={styles.online} /> : null);

const TerminalList = (props) => {
  const formatTimezone = (offsetInHours) => {
    if (offsetInHours) {
      return formatQuantity(offsetInHours, 'час', 'часа', 'часов');
    }
    return '';
  };
  const formatLastActivityTime = (timeString) => {
    if (timeString) {
      const now = moment().valueOf();
      const time = moment(timeString).valueOf();
      return `${formatDisplayInterval((now - time) / 1000)} назад`;
    }
    return '';
  };
  const organizationsCountFormatter = (organizations) => {
    if (organizations) return organizations.length;
    return 0;
  };
  const service = useTelemedicService();
  const [onlineTerminals, setOnlineTerminals] = useState([]);
  if (onlineTerminals === undefined) {
    setOnlineTerminals([]);
  }
  const intervalCallback = useCallback(() => {
    service.getActiveTerminals()
      .then((r) => {
        setOnlineTerminals(r.json.ids);
      })
      .catch((e) => console.log(e));
  }, []);
  useInterval(intervalCallback, 30000, true);
  return (
    <RestrictedList
      title="telemedic.terminals.label"
      filters={<TerminalFilter />}
      sort={{ field: 'name', order: 'ASC' }}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField
          label="#"
          source="id"
          sortable
        />
        <TextField
          label="telemedic.terminals.list.name"
          source="name"
          sortable
        />
        <TextField
          label="telemedic.terminals.list.teamViewerLogin"
          source="teamview_login"
          sortable={false}
        />
        <FormatValueField
          label="telemedic.terminals.list.buildDate"
          source="build_date"
          sortable
          formatter={formatDisplayDate}
        />
        <FormatValueField
          label="telemedic.terminals.list.deadline"
          source="deadline"
          sortable
          formatter={formatDisplayDate}
        />
        <TextField
          label="telemedic.terminals.list.type"
          source="type"
          sortable
        />
        <TextField
          label="telemedic.terminals.list.version"
          source="version"
          sortable
        />
        <FormatValueField
          label="telemedic.terminals.list.timezone"
          source="timezone"
          sortable
          formatter={formatTimezone}
        />
        <FunctionField
          label="telemedic.terminals.list.isOnline"
          render={
            (record) => <OnlineIndicator show={onlineTerminals.includes(record.id)} />
          }
        />
        <FormatValueField
          label="telemedic.terminals.list.lastActivityTime"
          source="last_activity_time"
          sortable
          formatter={formatLastActivityTime}
        />
        <FormatValueField
          label="telemedic.terminals.list.organizationsCount"
          source="organizations"
          sortable={false}
          formatter={organizationsCountFormatter}
        />
      </Datagrid>
    </RestrictedList>
  );
};

const dealersDataPromise = (service) => service.getDealerValues();

const organizationsDataPromise = (service) => service.getOrganizationValues();

const TerminalFilter = (props) => (
  <Filter key="terminal_filter" {...props}>
    <TextInput
      label="telemedic.terminals.list.id"
      source="id"
      resettable
    />
    <TextInput
      label="telemedic.terminals.list.name"
      source="name"
      alwaysOn
      resettable
    />
    <SelectInput
      label="telemedic.terminals.list.message"
      source="message"
      choices={[
        { id: 'true', name: 'telemedic.terminals.list.withMessage' },
        { id: 'false', name: 'telemedic.terminals.list.withoutMessage' },
      ]}
      alwaysOn
      resettable
    />
    <TelemedicDataSource
      dataPromise={dealersDataPromise}
      dataDefault={[]}
      dataAttrName="choices"
      alwaysOn
      resettable
    >
      <AutocompleteInput
        label="telemedic.terminals.list.dealer"
        choiceLabelField="name"
        choiceValueField="id"
        source="dealer_id"
      />
    </TelemedicDataSource>
    <TelemedicDataSource
      dataPromise={organizationsDataPromise}
      dataDefault={[]}
      dataAttrName="choices"
      alwaysOn
      resettable
    >
      <AutocompleteInput
        label="telemedic.terminals.list.organization"
        choiceLabelField="name"
        choiceValueField="id"
        source="organization_id"
      />
    </TelemedicDataSource>
  </Filter>
);

export { TerminalList };
