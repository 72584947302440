import React, { useCallback, useState } from 'react';
import { usePermissions, useTranslate, useNotify } from 'react-admin';

import OrganizationTitle from './OrganizationTitle';
import Button from '@material-ui/core/Button';
import styles from './OrganizationShow.module.css';

import { createTelemedicService } from '../../services/TelemedicService';
import { Section, Action } from '../../permissions';


const OrganizationTitleWithControls = ({ record }) => {
  const org_id = record.id;
  let telemedicService = createTelemedicService();
  let notify = useNotify();
  const regOrgInKaluga = useCallback(async () => {
    try {
        telemedicService = createTelemedicService();
        await telemedicService.regOrgInKaluga(org_id);
        notify('Регистрация успешна!', 'success');
    } catch (error) {
      notify('Ошибка при регистрации: ' + error.errorDescription, 'error');
    }
  }, [org_id, telemedicService, notify]);
  const translate = useTranslate();
  const { loaded, permissions } = usePermissions();
  const alreadyRegister = record.orgastral;
  const epdEnabled = record.epl_gis_epd_api;
  const showReviewButtons = loaded
    && epdEnabled === true && alreadyRegister === false;
    return (
    <>
      <OrganizationTitle record={record} />
      {showReviewButtons && (
        <>
          <Button
            className={styles.regAstralButton}
            variant="contained"
            color="contained"
            onClick={regOrgInKaluga}
          >
            {translate('telemedic.actions.regOrgKaluga')}
          </Button>
        </>
      )}
    </>
  );
};

export default OrganizationTitleWithControls;
