import React, { useCallback, useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {
  Show, SimpleShowLayout, BooleanField, TextField,
  usePermissions, useTranslate, showNotification
} from 'react-admin';

import Button from '@material-ui/core/Button';

import FormatValueField from '../../components/fields/FormatValueField';
import GridShowMediator from '../../components/GridShowMediator';
import ImageListField from '../../components/fields/ImageListField';
import TechInspectionTitle from './TechInspectionTitle';
import styles from './TechInspectionShow.module.css';
import withTechInspectionActions from './withTechInspectionActions';

import {TechInspectionResult} from '../../constants';
import {Section, Action} from '../../permissions';
import {RoutePath, navigateTo} from '../../routes';
import {
  formatDisplayDateTime, formatPersonName, formatTechInspectionResult,
  formatTechInspectionType
} from '../../utils/formatters';
import { getRutokenServiceInstance } from '../../services/RutokenService';

export const _TechInspectionShow = (props) => {
  const { showNotification, techInspectionActions, ...showProps} = props;
  const translate = useTranslate();
  const formatVehicle = ({manufacturer, model}) => {
    return `${manufacturer} ${model}`;
  };
  return (
    <Show
      title={
        <TechInspectionTitleWithControls
          showNotification = {showNotification}
          translate={translate}
          techInspectionActions={techInspectionActions}/>
      }
      actions={null}
      {...showProps}>
      <GridShowMediator container>
        <GridShowMediator xs={12} md={6} item>
          <SimpleShowLayout>
            <TextField label="telemedic.common.kisArtId" source="kis_art_id"/>
            <FormatValueField
              label="telemedic.techInspections.show.date"
              source="date"
              formatter={formatDisplayDateTime}/>
            <TextField
              label="telemedic.techInspections.show.organization"
              source="organization.name"/>
            <FormatValueField
              label="telemedic.techInspections.show.driver"
              source="driver"
              formatter={formatPersonName}/>
            <FormatValueField
              label="telemedic.techInspections.show.vehicle"
              source="vehicle"
              formatter={formatVehicle}/>
            <TextField
              label="telemedic.techInspections.show.inspectionPoint"
              source="inspection_point.name"/>
            <TextField
              label="telemedic.techInspections.show.carLicensePlate"
              source="vehicle.car_license_plate"/>
            <FormatValueField
              label="telemedic.techInspections.show.type"
              source="type_of_inspection"
              formatter={formatTechInspectionType}/>
            <TextField
              label="telemedic.techInspections.show.mileage"
              source="mileage"/>
            <TextField
              label="telemedic.techInspections.show.technicalCondition"
              source="technical_condition"/>
            <TextField
              label="telemedic.techInspections.show.note"
              source="note"/>
            <BooleanField
              label="telemedic.techInspections.show.lightning"
              source="lightning"/>
            <BooleanField
              label="telemedic.techInspections.show.brakingSystem"
              source="braking_system"/>
            <BooleanField
              label="telemedic.techInspections.show.steeringWheelPlay"
              source="steering_wheel_play"/>
            <BooleanField
              label="telemedic.techInspections.show.clutch"
              source="clutch"/>
            <BooleanField
              label="telemedic.techInspections.show.tirePressure"
              source="tire_pressure"/>
            <BooleanField
              label="telemedic.techInspections.show.wipers"
              source="wipers"/>
            <BooleanField
              label="telemedic.techInspections.show.soundSignals"
              source="sound_signals"/>
            <BooleanField
              label="telemedic.techInspections.show.locks"
              source="locks"/>
            <BooleanField
              label="telemedic.techInspections.show.fireExtinguisher"
              source="fire_extinguisher"/>
            <BooleanField
              label="telemedic.techInspections.show.towRope"
              source="tow_rope"/>
            <BooleanField
              label="telemedic.techInspections.show.jack"
              source="jack"/>
            <BooleanField
              label="telemedic.techInspections.show.firstAidKit"
              source="first_aid_kit"/>
            <BooleanField
              label="telemedic.techInspections.show.warningTriangle"
              source="warning_triangle"/>
            <BooleanField
              label="telemedic.techInspections.show.individualProtection"
              source="individual_protection"/>
            <BooleanField
              label="telemedic.techInspections.show.briefingRegime"
              source="briefing_regime"/>
            <BooleanField
              label="telemedic.techInspections.show.briefingChildren"
              source="briefing_children"/>
            <BooleanField
              label="telemedic.techInspections.show.gasEquipment"
              source="gas_equipment"/>
            <FormatValueField
              label="telemedic.techInspections.show.result"
              source="result"
              formatter={formatTechInspectionResult}/>
            <FormatValueField
              label="telemedic.techInspections.show.resultDatetime"
              source="result_datetime"
              formatter={formatDisplayDateTime}/>
          </SimpleShowLayout>
        </GridShowMediator>
        <GridShowMediator xs={12} md={6} item>
          <ImageListField
            label="telemedic.techInspections.show.photos"
            source="photo"/>
        </GridShowMediator>
      </GridShowMediator>
    </Show>
  );
}

const TechInspectionTitleWithControls = ({showNotification, record, translate, techInspectionActions}) => {
  const {loaded, permissions} = usePermissions();
  let epl_gis_epd_api = record.organization;
  if (record.organization) {
    epl_gis_epd_api = record.organization.epl_gis_epd_api;
  }
  const [checkToken, setCheckToken] = useState(false);
  const rutokenService = getRutokenServiceInstance();

  useEffect(() => {
    if (epl_gis_epd_api) {
      rutokenService.selectDevice()
        .then(() => setCheckToken(true))
        .catch(() => setCheckToken(false));
    } else {
      setCheckToken(false);
    }
  }, [epl_gis_epd_api, rutokenService]);

  if (epl_gis_epd_api) {
    techInspectionActions.setFlagforEpl(epl_gis_epd_api, record.organization.id, record.type_of_inspection)
  }

  const showReviewButtons = loaded
    && permissions.check(Section.TECH_INSPECTIONS, Action.REVIEW)
    && record.result === TechInspectionResult.AWAITING
    && (checkToken || epl_gis_epd_api === false);
  const admitVehicle = useCallback(() => {
    if (epl_gis_epd_api) {
      showNotification('telemedic.techInspections.show.sendGisEpdSignedFile');
    }
    techInspectionActions.admitVehicle(record.id, record.driver.id, record.organization.id, {
      onDone: () => window.location.reload()
    });
  }, [techInspectionActions, record, showNotification]);
  const notAdmitVehicle = useCallback(() => {
    techInspectionActions.notAdmitVehicle(record.id, {
      onDone: () => window.location.reload()
    });
  }, [techInspectionActions, record]);
  const fillTechInspection = useCallback(() => {
    navigateTo(RoutePath.TECH_INSPECTION_EDIT, {id: record.id});
  }, [record.id]);
  return (
    <React.Fragment>
      <TechInspectionTitle record={record}/>
      {showReviewButtons &&
        <React.Fragment>
          <Button
            className={styles.admissionButton}
            variant="contained"
            color="primary"
            onClick={admitVehicle}
          >
            {translate('telemedic.actions.admit')}
          </Button>
          <Button
            className={styles.nonAdmissionButton}
            variant="contained"
            onClick={notAdmitVehicle}
          >
            {translate('telemedic.actions.notAdmit')}
          </Button>
          <Button
            className={styles.fillButton}
            variant="contained"
            onClick={fillTechInspection}
          >
            {translate('telemedic.actions.fill')}
          </Button>
        </React.Fragment>
      }
    </React.Fragment>
  );
}

export const TechInspectionShow = connect(null, {showNotification})(withTechInspectionActions( _TechInspectionShow));
