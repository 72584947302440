// formats
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const API_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const DISPLAY_DATE_FORMAT = 'DD.MM.YYYY';
export const DISPLAY_TIME_FORMAT = 'HH:mm:ss';
export const DISPLAY_DATETIME_FORMAT = `${DISPLAY_DATE_FORMAT} ${DISPLAY_TIME_FORMAT}`;

export const SERVER_MONITORING_URL = 'https://cc.tm-se.ru/';
export const TERMINAL_MONITORING_URL = 'https://zb.tm-se.ru/';

export const ReportType = {
  REPORT_TYPE_ORGS: 'organizations',
  REPORT_TYPE_TERMS: 'terminals',
  REPORT_TYPE_DEALERS: 'dealers',
};

// API constants
export const CheckupType = {
  PRE_TRIP: 0,
  POST_TRIP: 1,
  PRE_SHIFT: 2,
  POST_SHIFT: 3,
  PRE_WATCH: 4,
  POST_WATCH: 5,
  AUTOMATIC: 6,
  PRE_FLIGHT: 7,
  POST_FLIGHT: 8,
  DURING_WORK_DAY: 9,
  PRE_AUTOMATIC: 10,
  POST_AUTOMATIC: 11,
};

export const CheckupResult = {
  NOT_ADMITTED: 0,
  ADMITTED: 1,
  AWAITING: 2,
  REPEAT: 3,
};

export const ErrorCode = {
  // server codes
  UNAUTHORIZED: 1,
  PERMISSION_DENIED: 2,
  INVALID_DATA: 3,
  NOT_FOUND: 4,
  SERVICE_UNAVAILABL: 5,
  // client codes
  RESPONSE_PARSE_ERROR: 1001,
  RESPONSE_READ_ERROR: 1002,
};

export const NonAdmissionReason = {
  ALCOHOL_NOT_ON_VIDEO: 1,
  BLOOD_PRESSURE_NOT_ON_VIDEO: 2,
  TEMPERATURE_NOT_ON_VIDEO: 3,
  INSUFFICIENT_LIGHTING: 4,
  WRONG_DRIVER: 5,
  BAD_PICTURE_QUALITY: 6,
  ALCOHOL_ABNORMAL: 7,
  BLOOD_PRESSURE_ABNORMAL: 8,
  PULSE_ABNORMAL: 9,
  TEMPERATURE_ABNORMAL: 10,
  INDIVIDUAL_PARAMETERS: 11,
  CUFF_WORN_NOT_CORRECTLY: 12,
  COMPLAINTS: 13,
  TAKE_OFF_OUTERWEAR: 14,
};

export const ContractStatus = {
  CONCLUDED: 'Заключен',
  TERMINATED: 'Расторгнут',
  TESTING: 'Тестирование',
};

export const PartnersEplApi = {
  EDIWEB: 1,
  ITCOM: 2,
}

export const OrganizationType = {
  LEGAL_ENTITY: 0,
  INDIVIDUAL_ENTREPRENEUR: 1,
};

export const MailingPeriodUnit = {
  DAY: 2,
  WEEK: 3,
  MONTH: 4,
};

export const PriceType = {
  PER_CHECKUP: 0,
  MONTHLY: 1,
};

export const ChooseTerminals = {
  TERMINAL_1: 0,
  TERMINAL_2: 1,
};

export const Role = {
  MEDIC: 2,
  DISPATCHER: 4,
  ORG_DIRECTOR: 5,
  ADMIN: 10,
  MED_ORG_DIRECTOR: 11,
  SUPPORT: 12,
  PARTNER: 13,
  MECHANIC: 14,
  USER: 15,
  MANAGER: 16,
};

export const Sex = {
  MALE: 0,
  FEMALE: 1,
};

export const TechInspectionType = {
  PRE_TRIP: 0,
  POST_TRIP: 1,
  PRE_SHIFT: 2,
  POST_SHIFT: 3,
  PRE_WATCH: 4,
  POST_WATCH: 5,
};

export const TechInspectionResult = {
  NOT_ADMITTED: 0,
  ADMITTED: 1,
  AWAITING: 2,
};

export const WaybillTerm = {
  DAY: 1,
  FEWDAYS: 2,
};

export const WhoCreated = {
  OWNER: 1,
  LANDLORD: 2,
};

export const postshiftCheckup = {
  YES: 1,
  NO: 2,
};

export const transportType = {
  commercialTransportation: 1,
  ownNeeds: 2,
  specialTransport: 3,
};

export const commercialTransportationType = {
  regularTransportation: 1,
  cargoTransportation: 2,
  requestTransportation: 3,
  taxiTransportation: 4,
  busTransportation: 5,
};

export const infoAboutTypeTransportation = {
  urban: 1,
  commuter: 2,
  intercity: 3,
};