import React, {useEffect, useCallback, useState} from 'react';
import {Confirm} from 'react-admin';

import sounds from '../sounds';

function withSoundNotification(WrappedComponent) {
  return (props) => {
    const [isPermissionDialogOpened, setPermissionDialogOpened] = useState(false);
    const [isSoundEnabled, setSoundEnabled] = useState(false)
    useEffect(() => {
      const testSound = new Audio(sounds.silence);
      testSound.play().then(() => {
        setSoundEnabled(true);
      }).catch((e) => {
        console.log(e);
        setPermissionDialogOpened(true);
      });
    }, []);
    const onSoundNotificationsEnabled = useCallback(() => {
      setSoundEnabled(true);
      setPermissionDialogOpened(false);
    }, []);
    const onSoundNotificationsDisabled = useCallback(() => {
      setSoundEnabled(false);
      setPermissionDialogOpened(false);
    }, []);
    const playSound = useCallback((sound) => {
      if (isSoundEnabled && sound) {
        sound.play();
      }
    }, [isSoundEnabled]);
    return (
      <React.Fragment>
        <WrappedComponent playSound={playSound} {...props}/>
        <Confirm
          isOpen={isPermissionDialogOpened}
          loading={false}
          title="telemedic.dialogs.soundNotification.title"
          content="telemedic.dialogs.soundNotification.content"
          onConfirm={onSoundNotificationsEnabled}
          onClose={onSoundNotificationsDisabled}/>
      </React.Fragment>
    );
  };
}

export default withSoundNotification;
