import React, { useCallback } from 'react';
import { usePermissions, useTranslate, useNotify } from 'react-admin';

import Button from '@material-ui/core/Button';

import WorkerTitle from './WorkerTitle';
import styles from './WorkerShow.module.css';
import { Role } from '../../constants';

import { createTelemedicService } from '../../services/TelemedicService';
import { getRole } from '../../storages/auth';

const WorkerTitleWithControls = ({ record }) => {
  const notify = useNotify();
  const translate = useTranslate();
  const role = getRole();
  const userIsAdmin = (role === Role.ADMIN);
  const { loaded, permissions } = usePermissions();
  const workerId = record.id;
  let telemedicService = createTelemedicService();
  const showReviewButtons = loaded && userIsAdmin;
  const clearMedicEcp = useCallback(async () => {
    try {
      telemedicService = createTelemedicService();
      await telemedicService.clearMedicEcp(workerId);
      notify('ЭЦП успешно удалена!', 'success');
    } catch (error) {
      notify(`Ошибка при удалении: ${error.message}`, 'error');
    }
  }, [workerId, telemedicService, notify]);
  return (
    <>
      <WorkerTitle record={record} />
      {showReviewButtons && (
        <>
          <Button
            className={styles.clearEcpButton}
            variant="contained"
            color="contained"
            onClick={clearMedicEcp}
          >
            {translate('telemedic.actions.clearMedicEcp')}
          </Button>
        </>
      )}
    </>
  );
};

export default WorkerTitleWithControls;
