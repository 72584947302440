import React, {useCallback, useState} from 'react';
import {
  SimpleForm, Toolbar, BooleanInput, SelectInput, TextInput, SaveButton,
  required
} from 'react-admin';
import {FormSpy} from 'react-final-form';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import FileListInput from '../../components/inputs/FileListInput';
import Spacer from '../../components/Spacer';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import {TechInspectionResult, TechInspectionType} from '../../constants';
import {enumChoices} from '../../utils/enums';
import {formatTechInspectionType} from '../../utils/formatters';

const TechInspectionForm = ({getInputProps, ...rest}) => {
  const [values, setValues] = useState({});
  const onValuesChange = useCallback(({values}) => {
    setValues(values);
  }, [setValues]);
  const service = useTelemedicService();
  const organizations = useTelemedicServiceJsonValue(() => {
    return service.getTechInspectionFormOrganizationValues();
  }, null, []);
  const drivers = useTelemedicServiceJsonValue((organizationId) => {
    return service.getTechInspectionFormDriverValues(organizationId);
  }, values.organization_id, []);
  const vehicles = useTelemedicServiceJsonValue((organizationId) => {
    return service.getTechInspectionFormVehicleValues(organizationId);
  }, values.organization_id, []);
  const inspectionPoints = useTelemedicServiceJsonValue((organizationId) => {
    return service.getTechInspectionFormInspectionPointValues(organizationId);
  }, values.organization_id, []);
  const typeChoices = enumChoices(TechInspectionType, formatTechInspectionType);
  const showInspectionPoint = !rest.record || !rest.record.inspection_point_id;
  return (
    <SimpleForm toolbar={<TechInspectionFormToolbar/>} {...rest}>
      <FormSpy
        subscription={{values: true}}
        onChange={onValuesChange}
      />
      <AutocompleteInput
        label="telemedic.techInspections.form.organization"
        choices={organizations}
        choiceLabelField="name"
        choiceValueField="id"
        source="organization_id"
        {...getInputProps('organization_id')}/>
      <AutocompleteInput
        label="telemedic.techInspections.form.driver"
        choices={drivers}
        choiceLabelField="name"
        choiceValueField="id"
        source="driver_id"
        {...getInputProps('driver_id')}/>
      <AutocompleteInput
        label="telemedic.techInspections.form.vehicle"
        choices={vehicles}
        choiceLabelField="name"
        choiceValueField="id"
        source="vehicle_id"
        {...getInputProps('vehicle_id')}/>
      {showInspectionPoint &&
        <AutocompleteInput
          label="telemedic.techInspections.form.inspectionPoint"
          choices={inspectionPoints}
          choiceLabelField="name"
          choiceValueField="id"
          source="inspection_point_id"
          {...getInputProps('inspection_point_id')}/>
      }
      <SelectInput
        label="telemedic.techInspections.form.type"
        source="type_of_inspection"
        choices={typeChoices}
        validate={required()}
        {...getInputProps('type_of_inspection')}/>
      <TextInput
        label="telemedic.techInspections.form.mileage"
        source="mileage"
        validate={required()}
        {...getInputProps('mileage')}/>
      <TextInput
        label="telemedic.techInspections.form.technicalCondition"
        source="technical_condition"
        validate={required()}
        {...getInputProps('technical_condition')}/>
      <TextInput
        label="telemedic.techInspections.form.note"
        source="note"
        multiline
        {...getInputProps('note')}/>
      <BooleanInput
        label="telemedic.techInspections.form.lightning"
        source="lightning"
        {...getInputProps('lightning')}/>
      <BooleanInput
        label="telemedic.techInspections.form.brakingSystem"
        source="braking_system"
        {...getInputProps('braking_system')}/>
      <BooleanInput
        label="telemedic.techInspections.form.steeringWheelPlay"
        source="steering_wheel_play"
        {...getInputProps('steering_wheel_play')}/>
      <BooleanInput
        label="telemedic.techInspections.form.clutch"
        source="clutch"
        {...getInputProps('clutch')}/>
      <BooleanInput
        label="telemedic.techInspections.form.tirePressure"
        source="tire_pressure"
        {...getInputProps('tire_pressure')}/>
      <BooleanInput
        label="telemedic.techInspections.form.wipers"
        source="wipers"
        {...getInputProps('wipers')}/>
      <BooleanInput
        label="telemedic.techInspections.form.soundSignals"
        source="sound_signals"
        {...getInputProps('sound_signals')}/>
      <BooleanInput
        label="telemedic.techInspections.form.locks"
        source="locks"
        {...getInputProps('locks')}/>
      <BooleanInput
        label="telemedic.techInspections.form.fireExtinguisher"
        source="fire_extinguisher"
        {...getInputProps('fire_extinguisher')}/>
      <BooleanInput
        label="telemedic.techInspections.form.towRope"
        source="tow_rope"
        {...getInputProps('tow_rope')}/>
      <BooleanInput
        label="telemedic.techInspections.form.jack"
        source="jack"
        {...getInputProps('jack')}/>
      <BooleanInput
        label="telemedic.techInspections.form.firstAidKit"
        source="first_aid_kit"
        {...getInputProps('first_aid_kit')}/>
      <BooleanInput
        label="telemedic.techInspections.form.warningTriangle"
        source="warning_triangle"
        {...getInputProps('warning_triangle')}/>
      <BooleanInput
        label="telemedic.techInspections.form.individualProtection"
        source="individual_protection"
        {...getInputProps('individual_protection')}/>
      <BooleanInput
        label="telemedic.techInspections.form.briefingRegime"
        source="briefing_regime"
        {...getInputProps('briefing_regime')}/>
      <BooleanInput
        label="telemedic.techInspections.form.briefingChildren"
        source="briefing_children"
        {...getInputProps('briefing_children')}/>
      <BooleanInput
        label="telemedic.techInspections.form.gasEquipment"
        source="gas_equipment"
        {...getInputProps('gas_equipment')}/>
      <FileListInput
        label="telemedic.techInspections.form.photos"
        source="photo_files"
        accept="image/*"
        {...getInputProps('photo_files')}/>
    </SimpleForm>
  );
}

const TechInspectionFormToolbar = (props) => {
  const transformOnAdmit = useCallback((data) => {
    return {...data, result: TechInspectionResult.ADMITTED};
  }, []);
  const transformOnNotAdmit = useCallback((data) => {
    return {...data, result: TechInspectionResult.NOT_ADMITTED};
  }, []);
  const transformOnAwait = useCallback((data) => {
    return {...data, result: TechInspectionResult.AWAITING};
  }, []);
  return (
    <Toolbar {...props} >
      <SaveButton
        icon={<CheckIcon/>}
        label="telemedic.techInspections.form.saveButton"
        submitOnEnter={false}
        transform={transformOnAwait}/>
    </Toolbar>
  );
}

export default TechInspectionForm;
