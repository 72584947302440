import React, { useEffect } from 'react';
import { useTranslate, useLogout } from 'react-admin';

import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './LoginStyles.module.css';

let didInit = false;

const EsiaLogoutPage = () => {
  const translate = useTranslate();
  const logout = useLogout();

  useEffect(() => {
    // Костыльно, но необходимо, так как по какой-то причине useEffect именно на этой странице выполняется дважды
    if (!didInit) {
      didInit = true;
      logout();
    }
  }, []);

  return (
    <div className={styles.esiaLoginContainer}>
      <CircularProgress size={24} />
      {translate('telemedic.login.awaitingLogoutResponse')}
    </div>
  );
};

export default EsiaLogoutPage;
