import moment from 'moment';

import {
  API_DATE_FORMAT, API_DATETIME_FORMAT,
  DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT, DISPLAY_DATETIME_FORMAT,
  CheckupType, CheckupResult, MailingPeriodUnit, NonAdmissionReason, PriceType,
  Role, Sex, TechInspectionResult, TechInspectionType, ChooseTerminals, WaybillTerm, 
  WhoCreated, postshiftCheckup, transportType, commercialTransportationType, infoAboutTypeTransportation,
  PartnersEplApi, OrganizationType
} from '../constants';

export const formatApiDate = (date) => {
  return date ? moment(date).format(API_DATE_FORMAT) : '';
}

export const formatApiDateTime = (date) => {
  return date ? moment(date).utc().format(API_DATETIME_FORMAT) : '';
}

export const formatOrg = (organization) => {
  return organization;
}

export const formatDateAsDateTime = (date, isPeriodEnd) => {
  const m = moment(date, API_DATE_FORMAT);
  if (isPeriodEnd) {
    m.set({hour: 23, minute: 59, second: 59, millisecond: 999});
  }
  return m.isValid() ? m.utc().format(API_DATETIME_FORMAT) : null;
}

export const parseDisplayDate = (date) => {
  const m = moment(date, DISPLAY_DATE_FORMAT, true);
  return m.isValid() ? m : null;
}

export const formatDisplayDate = (date) => {
  return date ? moment(date).format(DISPLAY_DATE_FORMAT) : '';
}

export const formatDisplayTime = (date) => {
  return date ? moment(date).format(DISPLAY_TIME_FORMAT) : '';
}

export const formatDisplayMoscowTime = (date) => {
  return date ? moment(date).utcOffset(3).format(DISPLAY_TIME_FORMAT) : '';
}

export const formatDisplayDateTime = (date) => {
  return date ? moment(date).format(DISPLAY_DATETIME_FORMAT) : '';
}

export const formatDisplayMoscowDateTime = (date) => {
  return date ? moment(date).utcOffset(3).format(DISPLAY_DATETIME_FORMAT) : '';
}

export const formatDisplayYear = (date) => {
  return moment(date).year();
}

const MONTH_NAMES = [
  'январь',
  'февраль',
  'март',
  'апрель',
  'май',
  'июнь',
  'июль',
  'август',
  'сентябрь',
  'октябрь',
  'ноябрь',
  'декабрь',
];

export const formatDisplayMonthName = (date) => {
  return MONTH_NAMES[moment(date).month()];
}

export const formatDisplayDay = (date) => {
  return moment(date).date();
}

const SECONDS_IN_A_MINUTE = 60;
const SECONDS_IN_AN_HOUR = 60 * SECONDS_IN_A_MINUTE
const SECONDS_IN_A_DAY = 24 * SECONDS_IN_AN_HOUR;

export const formatDisplayInterval = (secondsTotal) => {
  const days = Math.floor(secondsTotal / SECONDS_IN_A_DAY);
  const hours = Math.floor((secondsTotal % SECONDS_IN_A_DAY) / SECONDS_IN_AN_HOUR);
  const minutes = Math.floor((secondsTotal % SECONDS_IN_AN_HOUR) / SECONDS_IN_A_MINUTE);

  const result = [];
  if (days > 0) result.push(formatQuantity(days, 'день', 'дня', 'дней'));
  if (hours > 0) result.push(formatQuantity(hours, 'час', 'часа', 'часов'));
  if (minutes > 0) result.push(formatQuantity(minutes, 'минута', 'минуты', 'минут'));
  return result.join(' ');
}

export const formatTimeFromSeconds = (secondsTotal) => {
  let minutes = Math.floor(secondsTotal / 60);
  let seconds = Math.floor(secondsTotal) % 60;
  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;
  return `${minutes}:${seconds}`;
};

export const formatQuantity = (quantity, nominativeSingleForm, genitiveSingleForm, genitiveMultipleForm) => {
  let quantityReminder = quantity % 100;
  if (quantityReminder >= 20) quantityReminder %= 10;
  let form;
  if (quantityReminder === 0) form = genitiveMultipleForm;
  else if (quantityReminder === 1) form = nominativeSingleForm;
  else if (quantityReminder <= 4) form = genitiveSingleForm;
  else form = genitiveMultipleForm;
  return `${quantity} ${form}`;
};

const INSPECTION_TYPE_NAMES = {};
INSPECTION_TYPE_NAMES[CheckupType.PRE_TRIP] = 'Предрейсовый';
INSPECTION_TYPE_NAMES[CheckupType.POST_TRIP] = 'Послерейсовый';
INSPECTION_TYPE_NAMES[CheckupType.PRE_SHIFT] = 'Предсменный';
INSPECTION_TYPE_NAMES[CheckupType.POST_SHIFT] = 'Послесменный';
INSPECTION_TYPE_NAMES[CheckupType.PRE_WATCH] = 'Предвахтовый';
INSPECTION_TYPE_NAMES[CheckupType.POST_WATCH] = 'Послевахтовый';
INSPECTION_TYPE_NAMES[CheckupType.AUTOMATIC] = 'Автоматизированный';
INSPECTION_TYPE_NAMES[CheckupType.PRE_FLIGHT] = 'Предполётный';
INSPECTION_TYPE_NAMES[CheckupType.POST_FLIGHT] = 'Послеполётный';
INSPECTION_TYPE_NAMES[CheckupType.DURING_WORK_DAY] = 'В течении рабочего дня';
INSPECTION_TYPE_NAMES[CheckupType.PRE_AUTOMATIC] = 'Приход автоматизированный';
INSPECTION_TYPE_NAMES[CheckupType.POST_AUTOMATIC] = 'Уход автоматизированный';

export const formatInspectionType = (checkupType) => INSPECTION_TYPE_NAMES[checkupType];

const INSPECTION_TYPE_NAMES_GENITIVE_PLURAL = {};
INSPECTION_TYPE_NAMES_GENITIVE_PLURAL[CheckupType.PRE_TRIP] = 'Предрейсовых';
INSPECTION_TYPE_NAMES_GENITIVE_PLURAL[CheckupType.POST_TRIP] = 'Послерейсовых';
INSPECTION_TYPE_NAMES_GENITIVE_PLURAL[CheckupType.PRE_SHIFT] = 'Предсменных';
INSPECTION_TYPE_NAMES_GENITIVE_PLURAL[CheckupType.POST_SHIFT] = 'Послесменных';
INSPECTION_TYPE_NAMES_GENITIVE_PLURAL[CheckupType.PRE_WATCH] = 'Предвахтовых';
INSPECTION_TYPE_NAMES_GENITIVE_PLURAL[CheckupType.POST_WATCH] = 'Послевахтовых';
INSPECTION_TYPE_NAMES_GENITIVE_PLURAL[CheckupType.AUTOMATIC] = 'Автоматизированных';
INSPECTION_TYPE_NAMES_GENITIVE_PLURAL[CheckupType.PRE_FLIGHT] = 'Предполётных';
INSPECTION_TYPE_NAMES_GENITIVE_PLURAL[CheckupType.POST_FLIGHT] = 'Послеполётных';
INSPECTION_TYPE_NAMES_GENITIVE_PLURAL[CheckupType.DURING_WORK_DAY] = 'В течении рабочего дня';
INSPECTION_TYPE_NAMES_GENITIVE_PLURAL[CheckupType.PRE_AUTOMATIC] = 'Приход автоматизированных';
INSPECTION_TYPE_NAMES_GENITIVE_PLURAL[CheckupType.POST_AUTOMATIC] = 'Уход автоматизированных';

export const formatInspectionsTypeGenitivePlural = (checkupType) => INSPECTION_TYPE_NAMES_GENITIVE_PLURAL[checkupType];

const CHECKUP_RESULT_NAMES = {};
CHECKUP_RESULT_NAMES[CheckupResult.NOT_ADMITTED] = 'Недопуск';
CHECKUP_RESULT_NAMES[CheckupResult.ADMITTED] = 'Допуск';
CHECKUP_RESULT_NAMES[CheckupResult.AWAITING] = 'Ожидание';
CHECKUP_RESULT_NAMES[CheckupResult.REPEAT] = 'Повтор';

export const formatCheckupResult = (checkupResult) => {
  return CHECKUP_RESULT_NAMES[checkupResult];
};

const NON_ADMISSION_REASONS = {};
NON_ADMISSION_REASONS[NonAdmissionReason.ALCOHOL_NOT_ON_VIDEO] = 'Отсутствует алкотест';
NON_ADMISSION_REASONS[NonAdmissionReason.BLOOD_PRESSURE_NOT_ON_VIDEO] = 'Отсутствует замер давления';
NON_ADMISSION_REASONS[NonAdmissionReason.TEMPERATURE_NOT_ON_VIDEO] = 'Отсутствует замер температуры';
NON_ADMISSION_REASONS[NonAdmissionReason.INSUFFICIENT_LIGHTING] = 'Недостаточное освещение';
NON_ADMISSION_REASONS[NonAdmissionReason.WRONG_DRIVER] = 'Другой водитель';
NON_ADMISSION_REASONS[NonAdmissionReason.BAD_PICTURE_QUALITY] = 'Очень плохое изображение';
NON_ADMISSION_REASONS[NonAdmissionReason.ALCOHOL_ABNORMAL] = 'Алкоголь - за границами индивидуальных параметров';
NON_ADMISSION_REASONS[NonAdmissionReason.BLOOD_PRESSURE_ABNORMAL] = 'Давление - за границами индивидуальных параметров';
NON_ADMISSION_REASONS[NonAdmissionReason.PULSE_ABNORMAL] = 'Пульс - за границами индивидуальных параметров';
NON_ADMISSION_REASONS[NonAdmissionReason.TEMPERATURE_ABNORMAL] = 'Температура - за границами индивидуальных параметров';
NON_ADMISSION_REASONS[NonAdmissionReason.INDIVIDUAL_PARAMETERS] = 'Несоответствие индивидуальным параметрам';
NON_ADMISSION_REASONS[NonAdmissionReason.CUFF_WORN_NOT_CORRECTLY] = 'Неправильно надета манжета';
NON_ADMISSION_REASONS[NonAdmissionReason.COMPLAINTS] = 'Жалобы на самочувствие';
NON_ADMISSION_REASONS[NonAdmissionReason.TAKE_OFF_OUTERWEAR] = 'Снять верхнюю одежду, пройти осмотр заново';

const WAYBILLTERM = {};
WAYBILLTERM[WaybillTerm.DAY] = 'На 1 день';
WAYBILLTERM[WaybillTerm.FEWDAYS] = 'На несколько дней';

const WHOCREATED = {};
WHOCREATED[WhoCreated.OWNER] = 'Собственник';
WHOCREATED[WhoCreated.LANDLORD] = 'Арендодатель';

const POSTSHIFTCHECKUP = {};
POSTSHIFTCHECKUP[postshiftCheckup.YES] = 'Да';
POSTSHIFTCHECKUP[postshiftCheckup.NO] = 'Нет';

const TRANSPORTTYPE = {};
TRANSPORTTYPE[transportType.commercialTransportation] = 'Коммерческие перевозки';
TRANSPORTTYPE[transportType.ownNeeds] = 'Перевоз для собственных нужд';
TRANSPORTTYPE[transportType.specialTransport] = 'Передвижение и работа специальных транспортных средств';

const COMMERCIALTRANSPORTATIONTYPE = {};
COMMERCIALTRANSPORTATIONTYPE[commercialTransportationType.regularTransportation] = 'Регулярная перевозка пассажиров и багажа';
COMMERCIALTRANSPORTATIONTYPE[commercialTransportationType.cargoTransportation] = 'Перевозка грузов на основании договора перевозки грузов или договора фрахтования (в том числе по договору аренды транспортного средства с экипажем)';
COMMERCIALTRANSPORTATIONTYPE[commercialTransportationType.requestTransportation] = 'Перевозка пассажиров и багажа по заказу';
COMMERCIALTRANSPORTATIONTYPE[commercialTransportationType.taxiTransportation] = 'Перевозка пассажиров и багажа легковым такси';
COMMERCIALTRANSPORTATIONTYPE[commercialTransportationType.busTransportation] = 'Организованная перевозка групп детей автобусами (если по договору фрахтования)';

const INFOABOUTTYPETRANSPORTATION = {};
INFOABOUTTYPETRANSPORTATION[infoAboutTypeTransportation.urban] = 'Городское';
INFOABOUTTYPETRANSPORTATION[infoAboutTypeTransportation.commuter] = 'Пригородное';
INFOABOUTTYPETRANSPORTATION[infoAboutTypeTransportation.intercity] = 'Междугородное';

const PARTNERSEPLAPI = {};
PARTNERSEPLAPI[PartnersEplApi.EDIWEB] = 'Эдивеб';
PARTNERSEPLAPI[PartnersEplApi.ITCOM] = 'Айтиком';

export const formatNonAdmissionReason = (checkupNonAdmissionReason) => {
  return NON_ADMISSION_REASONS[checkupNonAdmissionReason];
}

export const formatWaybillTerm = (WaybillTerm) => {
  return WAYBILLTERM[WaybillTerm];
}

export const formatWhoCreated = (WhoCreated) => {
  return WHOCREATED[WhoCreated];
}

export const formatPostshiftCheckup = (postshiftCheckup) => {
  return POSTSHIFTCHECKUP[postshiftCheckup];
}

export const formatTransportType = (transportType) => {
  return TRANSPORTTYPE[transportType];
}

export const formatCommercialTransportationType = (commercialTransportationType) => {
  return COMMERCIALTRANSPORTATIONTYPE[commercialTransportationType];
}

export const formatInfoAboutTypeTransportation = (infoAboutTypeTransportation) => {
  return INFOABOUTTYPETRANSPORTATION[infoAboutTypeTransportation];
}

export const formatPartnersEplApi = (partnerEplApi) => {
  return PARTNERSEPLAPI[partnerEplApi];
}

export const formatContractStatus = (contractStatus) => {
  return contractStatus;
}

const ORGANIZATION_TYPE_NAMES = {};
ORGANIZATION_TYPE_NAMES[OrganizationType.LEGAL_ENTITY] = 'Юридическое лицо';
ORGANIZATION_TYPE_NAMES[OrganizationType.INDIVIDUAL_ENTREPRENEUR] = 'Индивидуальный предприниматель';

export const formatOrganizationType = (organizationType) => ORGANIZATION_TYPE_NAMES[organizationType];

const MAILING_PERIOD_UNIT = {};
MAILING_PERIOD_UNIT[MailingPeriodUnit.HOUR] = 'Час';
MAILING_PERIOD_UNIT[MailingPeriodUnit.DAY] = 'День';
MAILING_PERIOD_UNIT[MailingPeriodUnit.WEEK] = 'Неделя';
MAILING_PERIOD_UNIT[MailingPeriodUnit.MONTH] = 'Месяц';

export const formatMailingPeriodUnit = (mailingPeriodUnit) => {
  return MAILING_PERIOD_UNIT[mailingPeriodUnit];
};

export const formatMailingPeriod = (mailingPeriod, mailingPeriodUnit) => {
  if (mailingPeriodUnit === MailingPeriodUnit.HOUR) {
    return formatQuantity(mailingPeriod, 'час', 'часа', 'часов');
  }
  else if (mailingPeriodUnit === MailingPeriodUnit.DAY) {
    return formatQuantity(mailingPeriod, 'день', 'дня', 'дней');
  }
  else if (mailingPeriodUnit === MailingPeriodUnit.WEEK) {
    return formatQuantity(mailingPeriod, 'неделя', 'недели', 'недель');
  }
  else if (mailingPeriodUnit === MailingPeriodUnit.MONTH) {
    return formatQuantity(mailingPeriod, 'месяц', 'месяца', 'месяцев');
  }
  return 'Неподдерживаемый период: ' + mailingPeriodUnit;
};

const PRICE_TYPE_NAMES = {};
PRICE_TYPE_NAMES[PriceType.PER_CHECKUP] = 'За осмотр';
PRICE_TYPE_NAMES[PriceType.MONTHLY] = 'Ежемесячно';

export const formatPriceType = (priceType) => {
  return PRICE_TYPE_NAMES[priceType];
}

const TERMINAL_NAMES = {};
TERMINAL_NAMES[ChooseTerminals.TERMINAL_1] = 'Терминал 1';
TERMINAL_NAMES[ChooseTerminals.TERMINAL_2] = 'Терминал 2';

export const formatTerminalNames = (ChooseTerminals) => {
  return TERMINAL_NAMES[ChooseTerminals];
}

const ROLE_NAMES = {};
ROLE_NAMES[Role.MEDIC] = 'Медицинский работник';
ROLE_NAMES[Role.DISPATCHER] = 'Диспетчер';
ROLE_NAMES[Role.ORG_DIRECTOR] = 'Директор предприятия';
ROLE_NAMES[Role.ADMIN2] = 'Администратор тип 8';
ROLE_NAMES[Role.ADMIN] = 'Администратор';
ROLE_NAMES[Role.MED_ORG_DIRECTOR] = 'Директор медицинской организации';
ROLE_NAMES[Role.SUPPORT] = 'Сотрудник тех.поддержки';
ROLE_NAMES[Role.PARTNER] = 'Партнер';
ROLE_NAMES[Role.MECHANIC] = 'Механик';
ROLE_NAMES[Role.USER] = 'Пользователь';
ROLE_NAMES[Role.MANAGER] = 'Менеджер';

export const formatRole = (role) => {
  return ROLE_NAMES[role];
};

const SEX = {};
SEX[Sex.MALE] = 'Мужской';
SEX[Sex.FEMALE] = 'Женский';

export const formatSex = (sex) => {
  return SEX[sex];
};

const TECH_INSPECTION_RESULTS = {};
TECH_INSPECTION_RESULTS[TechInspectionResult.NOT_ADMITTED] = 'Недопуск';
TECH_INSPECTION_RESULTS[TechInspectionResult.ADMITTED] = 'Допуск';
TECH_INSPECTION_RESULTS[TechInspectionResult.AWAITING] = 'Ожидание';

export const formatTechInspectionResult = (techInspectionResult) => {
  return TECH_INSPECTION_RESULTS[techInspectionResult];
};

const TECH_INSPECTION_TYPES = {};
TECH_INSPECTION_TYPES[TechInspectionType.PRE_TRIP] = 'Предрейсовый';
TECH_INSPECTION_TYPES[TechInspectionType.POST_TRIP] = 'Послерейсовый';
TECH_INSPECTION_TYPES[TechInspectionType.PRE_SHIFT] = 'Предсменный';
TECH_INSPECTION_TYPES[TechInspectionType.POST_SHIFT] = 'Послесменный';
TECH_INSPECTION_TYPES[TechInspectionType.PRE_WATCH] = 'Предвахтовый';
TECH_INSPECTION_TYPES[TechInspectionType.POST_WATCH] = 'Послевахтовый';

export const formatTechInspectionType = (techInspectionType) => {
  return TECH_INSPECTION_TYPES[techInspectionType];
}

export const formatPersonName = (person) => {
  if (person) return `${person.last_name} ${person.first_name} ${person.middle_name}`;
  return '';
}
