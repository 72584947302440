import rutoken from 'rutoken';
import './cadesplugin_api';

class RutokenService {

  constructor(plugin) {
    this.pluginPromise = rutoken.ready.then(() => {
      this._log('initialization...');
      if (window.chrome) {
        return rutoken.isExtensionInstalled();
      } else {
        return Promise.resolve(true);
      }
    }).then((result) => {
      if (result) {
        return rutoken.isPluginInstalled();
      } else {
        return Promise.reject(new Error('Не установлено расширение Рутокен!'));
      }
    }).then((result) => {
      if (result) {
        return rutoken.loadPlugin();
      } else {
        return Promise.reject(new Error('Плагин Рутокен не установлен!'));
      }
    }).then((plugin) => {
      this._log('initialization: ok');
      return plugin;
    });
    this.lastDeviceId = null;
    this.lastCertificateId = null;
  }

  signCreate(dataToSign) {
    return new Promise((resolve, reject) => {
        window.cadesplugin.async_spawn(function* (args) {
            var err;
            const oStore = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.Store");
            yield oStore.Open(window.cadesplugin.CAPICOM_CURRENT_USER_STORE, window.cadesplugin.CAPICOM_MY_STORE,
                window.cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);
            
            const certificates = yield oStore.Certificates;
            console.log(certificates)
            const certsCount = yield certificates.Count;
            if (certsCount === 0) {
                err = "Certificate not found";
                console.log(`ERROR: ${err}`)
                args[1](err);
            }
            const oCertificate = yield certificates.Item(1);
            const oSigner = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
            yield oSigner.propset_Certificate(oCertificate);
            yield oSigner.propset_CheckCertificate(true);

            const oSignedData = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
            yield oSignedData.propset_ContentEncoding(window.cadesplugin.CADESCOM_BASE64_TO_BINARY);
            yield oSignedData.propset_Content(dataToSign);

            var sSignedMessage = '';
            try {
                sSignedMessage = yield oSignedData.SignCades(oSigner, window.cadesplugin.CADESCOM_CADES_BES, true);
            }
            catch (e) {
              err = window.cadesplugin.getLastError(e);
              args[1](err)
              console.log(`Failed to create signature. Error: ${err}`);
            }
            yield oStore.Close();
            return args[0](sSignedMessage);
        }, resolve, reject);
    });
  }

  verify(data, sSignedMessage) {
    if (!window.cadesplugin) {
      return
    }
    return new Promise((resolve, reject) => {
        window.cadesplugin.async_spawn(function* (args)  {
            var err;
            const oSignedData = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
            yield oSignedData.propset_ContentEncoding(window.cadesplugin.CADESCOM_BASE64_TO_BINARY);
            yield oSignedData.propset_Content(data);
            try {
                yield oSignedData.VerifyCades(sSignedMessage, window.cadesplugin.CADESCOM_CADES_BES, true);
            }
            catch (e) {
                err = window.cadesplugin.getLastError(e);
                alert("Failed to verify signature. Error: " + err);
                return args[1](err);
            }
            return args[0]();
        }, resolve, reject);
    });
  }

  checkDeviceAvailability() {
    return this.selectDevice().then(() => {});
  }

  selectDevice() {
    this._log('selectDevice...');
    return this.pluginPromise.then((plugin) => {
      return plugin.enumerateDevices().then((devices) => {
        if (devices.length > 0) {
          this._log('selectDevice: ok');
          this.lastDeviceId = devices[0];
          return devices[0];
        }
        return Promise.reject(new Error('ЭЦП токен не вставлен или не подходящий!'));
      });
    });
  }

  login(deviceId) {
    this._log('login...');
    return this.pluginPromise.then((plugin) => {
      return plugin.getDeviceInfo(deviceId, plugin.TOKEN_INFO_IS_LOGGED_IN).then((loggedIn) => {
        if (!loggedIn) {
          return plugin.login(deviceId, '12345678');
        }
      }).then(() => {
        this._log('login: ok');
      });
    });
  }

  selectCertificate(deviceId) {
    this._log('selectCertificate...');
    return this.pluginPromise.then((plugin) => {
      var categories = [
        plugin.CERT_CATEGORY_USER, plugin.CERT_CATEGORY_CA,
        plugin.CERT_CATEGORY_OTHER, plugin.CERT_CATEGORY_UNSPEC
      ];
      return this._getActiveCertificateIdInCategories(plugin, deviceId, categories).then((certificate) => {
        if (certificate) {
          this._log('selectCertificate: ok');
          this.lastCertificateId = certificate.id;
          return certificate;
        }
        return Promise.reject(new Error('Действующий сертификат не найден!'));
      });
    });
  }

  getCertificatePublicKey(deviceId, certificateId) {
    this._log('getCertificatePublicKey...');
    return this.pluginPromise.then((plugin) => {
      return new Promise((resolve, reject) => {
        var promise = plugin.getKeyByCertificate(deviceId, certificateId);
        if (typeof(promise.catch) == 'function') {
          promise.then((keyId) => {
            return plugin.getPublicKeyValue(deviceId, keyId, {});
          }).then((keyValue) => {
            this._log('getCertificatePublicKey: ok');
            resolve(keyValue);
          }).catch((e) => {
            this._log('getCertificatePublicKey: fail');
            resolve(certificateId);
          });
        }
        else {
          resolve(certificateId);
        }
      });
    });
  }

  getCertificate(deviceId, certificateId) {
    this._log('getCertificate...');
    return this.pluginPromise.then((plugin) => {
      return plugin.getCertificate(deviceId, certificateId).then((pem) => {
        this._log('getCertificate: ok');
        return pem;
      });
    });
  }

  parseCertificate(deviceId, certificateId) {
    this._log('parseCertificate...');
    return this.pluginPromise.then((plugin) => {
      return plugin.parseCertificate(deviceId, certificateId).then((certificateData) => {
        this._log('parseCertificate: ok');
        return certificateData;
      });
    });
  }

  sign(deviceId, certificateId, data) {
    this._log('sign...');
    return this.signCreate(data).then(
        (signedData) => (
          this.verify(data, signedData).then(
            () => {
              this._log("Signature verified");
              return signedData
            },
            (err) => {
              throw Error(err)
            }
          )
        ),
        (err) => {
          throw new Error(err)
        }
    );
  }

  //shortcuts
  getDeviceAndCertificateId() {
    var deviceId = null;
    return this.selectDevice().then((selectedDeviceId) => {
      deviceId = selectedDeviceId;
      return this.login(deviceId);
    }).then(() => {
      return this.selectCertificate(deviceId);
    }).then((selectedCertificate) => {
      return {
        deviceId: deviceId,
        certificateId: selectedCertificate.id
      };
    });
  }

  withCertificateIdAndDevice(promiseFactory) {
    if (this.lastDeviceId !== null && this.lastCertificateId !== null) {
      return promiseFactory({deviceId: this.lastDeviceId, certificateId: this.lastCertificateId})
        .catch((e) => {
            console.log(e, typeof(e));
            // 3 - DEVICE_NOT_FOUND, 22 - CERTIFICATE_NOT_FOUND
            if (e === 3 || e === 22) {
                console.log('Re-request deviceId and certificateId');
                return this._getDeviceCertificateIdAndRun(promiseFactory);
            }
            return Promise.reject(e);
        });
    } else {
      return this._getDeviceCertificateIdAndRun(promiseFactory)
    }
  }

  _getDeviceCertificateIdAndRun(promiseFactory) {
    return this.getDeviceAndCertificateId().then((result) => {
      return promiseFactory(result);
    });
  }

  _findActiveCertificateIdInList(plugin, deviceId, certificates) {
    if (certificates.length > 0) {
      var certificateId = certificates[certificates.length - 1];
      return plugin.parseCertificate(deviceId, certificateId).then((certificate) => {
        var currentDate = (new Date()).toISOString();
        if (currentDate >= certificate.validNotBefore && currentDate <= certificate.validNotAfter) {
          return Promise.resolve({
            id: certificateId,
            data: certificate,
          });
        }
        return this._findActiveCertificateIdInList(plugin, deviceId, certificates.slice(0, -1));
      });
    }
    return Promise.resolve(null);
  }

  _getActiveCertificateIdInCategories(plugin, deviceId, categories) {
    if (categories.length > 0) {
      return plugin.enumerateCertificates(deviceId, categories[0]).then((certificates) => {
        return this._findActiveCertificateIdInList(plugin, deviceId, certificates);
      }).then((certificate) => {
        if (certificate != null) {
          return Promise.resolve(certificate);
        }
        return this._getActiveCertificateIdInCategories(plugin, deviceId, categories.slice(1));
      });
    }
    return Promise.resolve(null);
  }

  _log(s) {
    console.log('rutoken wrapper:', s, Date());
  }
}

var instance = null;
export const getRutokenServiceInstance = () => {
  if (instance == null) {
    instance = new RutokenService();
  }
  return instance;
}

export default RutokenService;
