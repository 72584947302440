import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';

import CircularProgress from '@material-ui/core/CircularProgress';

import { RoutePath, navigateTo } from '../../routes';
import { createTelemedicService } from '../../services/TelemedicService';
import styles from './LoginStyles.module.css';

let didInit = false;

const EsiaLoginPage = () => {
  const translate = useTranslate();

  const authWithEsia = async () => {
    const paramsRaw = document.location.hash.split('?')[1];
    const params = new URLSearchParams(paramsRaw);
    const paramsObj = Object.fromEntries(params);
    const service = createTelemedicService(); // Используется вместо useTelemedicService, так как оный преждевременно вызывает abort()
    const response = await service.getEsiaLoginResponse(paramsObj).catch((e) => e);
    if (!response || response.status !== 200) {
      const errorMessage = response.errorDescription ? response.errorDescription : translate('telemedic.login.esiaGeneralError');
      navigateTo(RoutePath.LOGIN, {}, { error: errorMessage });
      return;
    }
    localStorage.setItem('token', response.json.token);
    localStorage.setItem('sections', JSON.stringify(response.json.sections));
    localStorage.setItem('role', response.json.role);
    localStorage.setItem('user_info', JSON.stringify(response.json.data));
    localStorage.setItem('is_token_attached', response.json.is_token_attached);
    localStorage.setItem('did_login_with_esia', true);
    localStorage.setItem('token', response.json.token);

    navigateTo(RoutePath.DASHBOARD);
  };

  useEffect(() => {
    // Костыльно, но необходимо, так как по какой-то причине useEffect именно на этой странице выполняется дважды
    if (!didInit) {
      didInit = true;
      authWithEsia();
    }
  }, []);

  return (
    <div className={styles.esiaLoginContainer}>
      <CircularProgress size={24} />
      {translate('telemedic.login.awaitingResponse')}
    </div>
  );
};

export default EsiaLoginPage;
