import React from 'react';
import {useTranslate} from 'react-admin';

import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';

import styles from './HorizontalShowLayout.module.css';

const HorizontalShowLayout = ({children, ...rest}) => {
  const translate = useTranslate();
  return (
    <div className={styles.container}>
      {React.Children.map(children, (child) => (
        <div className={styles.row}>
          {!!child.props.label &&
            <div className={styles.column1}>
              <FormLabel>
                {translate(child.props.label)}
              </FormLabel>
            </div>
          }
          <div className={styles.column2}>
            {
              React.isValidElement(child) ? React.cloneElement(child, rest) : (
                <Typography variant="body2">
                  {React.cloneElement(child, rest)}
                </Typography>
              )
            }
          </div>
        </div>
      ))}
    </div>
  );
}

export default HorizontalShowLayout;
