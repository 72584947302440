import { useTranslate } from 'react-admin';
import lodashMemoize from 'lodash/memoize';

const memoize = (fn) => lodashMemoize(fn, (...args) => JSON.stringify(args));
const decimalRegEx = /^\d*\.?\d*$/;


export const isDecimal = memoize((translate, message = 'telemedic.errors.decimalError') => {
    return Object.assign(
        (value, values) =>
            !value.match(decimalRegEx) || value.match(decimalRegEx).length > 1
                ? translate(message)
                : undefined,
        { isRequired: true }
    )
});
