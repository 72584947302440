import download from 'downloadjs';

import HttpService from './HttpService';
import { API_ROOT } from '../config';
import { getAuthToken } from '../storages/auth';

import {
  formatApiDate, formatApiDateTime, formatDisplayDate, formatDisplayDateTime,
} from '../utils/formatters';
import { downloadDocx, downloadExcel, downloadPdf } from '../utils/download';

class TelemedicService {
  constructor(authToken) {
    this.service = new HttpService(API_ROOT, authToken);
  }

  attachToken(
    username, password, certificateId, certificateContent,
    certificateValidityFrom, certificateValidityTo, certificatePublicKey
  ) {
    return this.service.postJsonGetJson('/attach_token', {
      'login': username,
      'password': password,
      'cert_id': certificateId,
      'cert_content': certificateContent,
      'cert_date_from': certificateValidityFrom,
      'cert_date_to': certificateValidityTo,
      'cert_public_key': certificatePublicKey
    });
  }

  getEsiaLogin() {
    return this.service.getJson('/oauth2/login');
  }

  getEsiaLogout() {
    return this.service.getJson('/oauth2/logout');
  }

  getEsiaLoginResponse(esia_login_params) {
    return this.service.getJson('/oauth2/login_response', esia_login_params);
  }

  unlinkEsiaFromProfile() {
    return this.service.getJson('/oauth2/unlink');
  }

  getDealerValues() {
    return this.service.getJson('/dealers/values');
  }

  getDealerPayments({dealerId, limit, sort}) {
    return this.service.getJson('/dealer_payments', {
      filter_dealer_id: dealerId,
      page_size: limit,
      sort: sort
    });
  }

  getDealerPaymentFormDealerValues() {
    return this.service.getJson('/dealer_payments/form/dealers');
  }

  getDealersResponsibleValues() {
    return this.service.getJson('/dealers_responsible/values');
  }

  getDiskSpace() {
    return this.service.getJson('/disk_space');
  }

  getInspectionPointValues() {
    return this.service.getJson('/inspection_points/values');
  }

  getKisArtWorkersData({organizationId, workerId}) {
    return this.service.getJsonData('/kis_art_workers', {
      'filter[organization_id]': organizationId,
      'filter[worker_id]': workerId
    });
  }

  getKisArtWorkerFormOrganizationsData() {
    return this.service.getJsonData('/kis_art_workers/form/organizations');
  }

  createKisArtWorker({organizationId, workerId, kisArtId}) {
    return this.service.postJsonGetJson('/kis_art_workers', {
      data: {
        attributes: {
          organization_id: organizationId,
          worker_id: workerId,
          kis_art_id: kisArtId
        }
      }
    });
  }

  updateKisArtWorker(kisArtWorkerId, {organizationId, workerId, kisArtId}) {
    return this.service.patchJsonGetJson('/kis_art_workers/' + kisArtWorkerId, {
      data: {
        attributes: {
          organization_id: organizationId,
          worker_id: workerId,
          kis_art_id: kisArtId
        }
      }
    });
  }

  deleteKisArtWorker(kisArtWorkerId) {
    return this.service.deleteGetJson('/kis_art_workers/' + kisArtWorkerId);
  }

  getMedOrganizationFormDirectorValues() {
    return this.service.getJson('/med_organizations/form/directors');
  }

  getMedOrganizationFormPartnerValues() {
    return this.service.getJson('/med_organizations/form/partners');
  }

  getTestQuestionCategoryValues() {
    return this.service.getJson('/test_question_categories/values');
  }

  getTestResults({
    dateCreatedFrom, pageSize, page, sort
  }) {
    const filters = {};
    if (dateCreatedFrom) filters['filter[created_from]'] = dateCreatedFrom;
    if (pageSize) filters['page[size]'] = pageSize;
    if (page) filters['page[number]'] = page;
    if (sort) filters['sort'] = sort;
    return this.service.getJson('/test_results', filters);
  }

  getTestResultsUpdates({
    createdFrom
  }) {
    const filters = {
      created_from: createdFrom
    };
    return this.service.getJson('/test_results/updates', filters);
  }

  getOrgPayments({organizationId, pageSize, sort}) {
    return this.service.getJson('/org_payments', {
      filter_organization_id: organizationId,
      page_size: pageSize,
      sort: sort
    });
  }

  getOrgPaymentFormOrganizationValues() {
    return this.service.getJson('/org_payments/form/organizations');
  }

  getOrganization(organizationId) {
    return this.service.getJson('/organizations/' + organizationId);
  }

  getOrganizationValues() {
    return this.service.getJson('/organizations/values');
  }

  getOrganizationFormDealerValues() {
    return this.service.getJson('/organizations/form/dealers');
  }

  getOrganizationFormTerminalValues() {
    return this.service.getJson('/organizations/form/terminals');
  }

  getOrganizationFormDealersResponsibleValues() {
    return this.service.getJson('/organizations/form/dealers_responsible');
  }

  getRoleValues() {
    return this.service.getJson('/roles/values');
  }

  getTechInspectionFormOrganizationValues() {
    return this.service.getJson('/tech_inspections/form/organizations');
  }

  getTechInspectionFormDriverValues(organizationId) {
    return this.service.getJson('/tech_inspections/form/drivers', {
      organization_id: organizationId
    });
  }

  getTechInspectionFormVehicleValues(organizationId) {
    return this.service.getJson('/tech_inspections/form/vehicles', {
      organization_id: organizationId
    });
  }

  getTechInspectionFormInspectionPointValues(organizationId) {
    return this.service.getJson('/tech_inspections/form/inspection_points', {
      organization_id: organizationId
    });
  }

  restartTerminal(terminalId) {
    return this.service.getJson(`/terminals/${terminalId}/restart`);
  }

  getTerminalValues() {
    return this.service.getJson('/terminals/values');
  }

  getTerminalCoordinates() {
    return this.service.getJson('/terminals/coordinates');
  }

  getTerminalFormOrganizationValues() {
    return this.service.getJson('/terminals/form/organizations');
  }

  getTerminalFormInpsectionPointValues() {
    return this.service.getJson('/terminals/form/inspection_points');
  }

  clearTerminalCache(terminalId) {
    return this.service.getJson(`/terminals/${terminalId}/clear_cache`);
  }

  getUser(userId) {
    return this.service.getJson('/users/' + userId);
  }

  getUserValues(organizationId) {
    return this.service.getJson('/users/values', {
      organization_id: organizationId
    });
  }

  getUserFormOrganizationValues() {
    return this.service.getJson('/users/form/organizations');
  }

  getVehicle(vehicleId) {
    return this.service.getJson('/vehicles/' + vehicleId);
  }

  getVehicleValues(organizationId) {
    return this.service.getJson('/vehicles/values', {
      organization_id: organizationId
    });
  }

  getVehicleFormOrganizationValues() {
    return this.service.getJson('/vehicles/form/organizations');
  }

  getWorkerValuesByOrg(organizationId) {
    return this.service.getJson('/workers/values', {
      organization_id: organizationId
    });
  }

  getWorkerValues(role) {
    return this.service.getJson('/workers/values', {
      role: role
    });
  }

  getWorkerFormMedOrganizationValues() {
    return this.service.getJson('/workers/form/med_organizations');
  }

  getWorkerFormOrganizationValues() {
    return this.service.getJson('/workers/form/organizations');
  }

  getWorkerFormInspectionPointValues(organizationIds) {
    return this.service.postJsonGetJson('/workers/form/inspection_points', {
      'organization_ids': organizationIds
    });
  }

  getWorkerFormRoleValues(role) {
    return this.service.getJson('/workers/form/roles');
  }

  getLastCheckupDate() {
    return this.service.getJson('/statistics_checkup/last_checkup_date');
  }

  getCheckups({
    dateCreatedFrom, resultMedic, userId, userName, organizationId, terminalNames, pageSize, page, sort, status
  }) {
    const filters = {};
    if (dateCreatedFrom) filters['filter[created_from]'] = dateCreatedFrom;
    if (resultMedic) filters['filter[result_medic]'] = resultMedic;
    if (userId) filters['filter[user_id]'] = userId;
    if (userName) filters['filter[user_name]'] = userName;
    if (organizationId) filters['filter[organization_id]'] = organizationId;
    if (terminalNames) filters['filter[terminal_names][]'] = terminalNames;
    if (pageSize) filters['page[size]'] = pageSize;
    if (page) filters['page[number]'] = page;
    if (sort) filters['sort'] = sort;
    if (status) filters['filter[result_medic]'] = status;
    return this.service.getJson('/checkups', filters);
  }

  getCheckupsUpdates({
    createdFrom, updatedFrom, userName, organizationId, terminalNames, status,
  }) {
    const filters = {
      created_from: createdFrom,
      updated_from: updatedFrom,
    };
    if (userName) filters['filter[user_name]'] = userName;
    if (organizationId) filters['filter[organization_id]'] = organizationId;
    if (terminalNames) filters['filter[terminal_names][]'] = terminalNames;
    if (status) filters['filter[result_medic]'] = status;
    return this.service.getJson('/checkups/updates', filters);
  }

  getEncodedCheckupXml(checkupId, certPublicKey) {
    return this.service.getJson('/signed_checkup_astral/get_xml_checkup', {
      checkup_id: checkupId,
      cert_public_key: certPublicKey,
    });
  }

  uploadSignedCheckupXml(checkupId, journal, signature) {
    return this.service.postJsonGetJson('/signed_checkup_astral/upload_signed_xml', {
      checkup_id: checkupId,
      journal: journal,
      signature: signature
    });
  }

  getQRCode(checkupId) {
    return this.service.postJsonGetBlob(`/checkups/${checkupId}/print/qr-code`)
      .then(({ blob }) => {
        download(
          blob,
          'QR-код осмотра.png',
          'image/png',
        );
      });
  }

  getDoctorVerdict(checkupId) {
    return this.service.postJsonGetBlob(`/checkups/${checkupId}/print/doctor-verdict`)
      .then(({ blob }) => {
        downloadDocx(blob, 'Дубликат заключения врача');
      });
  }

  getCheckupReport(checkupId) {
    return this.service.postJsonGetBlob(`/checkups/${checkupId}/print/checkup-report`)
      .then(({ blob }) => {
        downloadDocx(blob, 'Заключение медосмотра');
      });
  }

  getTaxiWaybill(checkupId) {
    return this.service
      .postJsonGetBlob(`/checkups/${checkupId}/print/waybill`, { tz_offset: (new Date()).getTimezoneOffset() })
      .then(({ blob }) => {
        downloadPdf(blob, 'Путевой лист с медосмотром');
      });
  }

  getNonAdmissionPaper(checkupId) {
    return this.service.postJsonGetBlob(`/checkups/${checkupId}/print/non-admission-paper`)
      .then(({ blob }) => {
        downloadDocx(blob, 'Направление в лечебно-профилактическое учреждение');
      });
  }

  getSobrietyProtocol(checkupId) {
    return this.service.postJsonGetBlob(`/checkups/${checkupId}/print/sobriety-protocol`)
      .then(({ blob }) => {
        downloadPdf(blob, 'Протокол контроля трезвости водителя автотранспортного средства');
      });
  }

  admitUser(checkupId, certificateId) {
    return this.service.postJsonGetJson('/checkups/' + checkupId + '/admit', {
      cert_id: certificateId
    });
  }

  revertCheckupAdmit(checkupId) {
    return this.service.postJsonGetJson('/checkups/' + checkupId + '/revert');
  }

  attachCheckupEpl(checkupId) {
    return this.service.postJsonGetJson('/checkups/' + checkupId + '/attach_epl');
  }

  getT2PartnerEpl(checkupId) {
    return this.service.getJson('/checkups/' + checkupId + '/get_t2_partner_epl');
  }

  uploadT2PartnerEpl(checkupId, journal, signature) {
    return this.service.postJsonGetJson('/checkups/upload_t2_partner_epl', {
      checkup_id: checkupId,
      journal: journal,
      signature: signature
    });
  }

  notAdmitUser(checkupId, certificateId, nonAdmissionReasons, comment) {
    return this.service.postJsonGetJson('/checkups/' + checkupId + '/not_admit', {
      cert_id: certificateId,
      'non_admission_reasons': nonAdmissionReasons,
      'comment': comment
    });
  }

  requestCheckupRepeat(checkupId, certificateId) {
    return this.service.postJsonGetJson('/checkups/' + checkupId + '/repeat', {
      cert_id: certificateId
    });
  }

  notAdmitUserAuto(checkupId, certificateId) {
    return this.service.postJsonGetJson('/checkups/' + checkupId + '/not_admit_auto', {
      cert_id: certificateId
    });
  }

  admitVehicle(techInspectionId) {
    return this.service.postJsonGetJson('/tech_inspections/' + techInspectionId + '/admit');
  }

  revertVehicleAdmit(tech_inspections_id) {
    return this.service.postJsonGetJson('/tech_inspections/' + tech_inspections_id + '/revert');
  }

  notAdmitVehicle(techInspectionId) {
    return this.service.postJsonGetJson('/tech_inspections/' + techInspectionId + '/not_admit');
  }

  getTechInspectionStatistics(dateFrom, dateTo, techInspectionType, organizationId, withoutRepeats) {
    return this.service.postJsonGetJson('/statistics_tech/inspections', {
      date_from: formatApiDate(dateFrom),
      date_to: formatApiDate(dateTo),
      inspection_type: techInspectionType,
      organization: organizationId,
      without_repeats: withoutRepeats,
    });
  }

  exportTechInspectionsStatistics(dateFrom, dateTo, techInspectionType, organizationId, withoutRepeats) {
    return this.service.postJsonGetBlob('/statistics_tech/inspections/export', {
      date_from: formatApiDate(dateFrom),
      date_to: formatApiDate(dateTo),
      inspection_type: techInspectionType,
      organization: organizationId,
      without_repeats: withoutRepeats,
    }).then(({ blob }) => {
      downloadExcel(blob, `Тех осмотры ${formatDisplayDate(dateFrom)} - ${formatDisplayDate(dateTo)}`);
    });
  }

  exportExtendedTechInspectionsStatistics(dateFrom, dateTo, techInspectionType, organizationId, withoutRepeats) {
    return this.service.postJsonGetBlob('/statistics_tech/inspections_extended/export', {
      date_from: formatApiDate(dateFrom),
      date_to: formatApiDate(dateTo),
      inspection_type: techInspectionType,
      organization: organizationId,
      without_repeats: withoutRepeats,
    }).then(({ blob }) => {
      downloadExcel(blob, `Тех осмотры ${formatDisplayDate(dateFrom)} - ${formatDisplayDate(dateTo)}`);
    });
  }

  getCheckupsStatistics(dateFrom, dateTo, checkupType, organizationId, terminalId, withoutRepeats, without_repeats_2v) {
    return this.service.postJsonGetJson('/statistics_checkup/checkups', {
      date_from: formatApiDate(dateFrom),
      date_to: formatApiDate(dateTo),
      checkup_type: checkupType,
      organization: organizationId,
      terminal: terminalId,
      without_repeats: withoutRepeats,
      without_repeats_2v: without_repeats_2v,
    });
  }

  exportCheckupsStatistics(dateFrom, dateTo, checkupType, organizationId, terminalId, withoutRepeats, without_repeats_2v) {
    return this.service.postJsonGetBlob('/statistics_checkup/checkups/export', {
      date_from: formatApiDate(dateFrom),
      date_to: formatApiDate(dateTo),
      checkup_type: checkupType,
      organization: organizationId,
      terminal: terminalId,
      without_repeats: withoutRepeats,
      without_repeats_2v: without_repeats_2v,
    }).then(({blob}) => {
      downloadExcel(blob, `Осмотры ${formatDisplayDate(dateFrom)} - ${formatDisplayDate(dateTo)}`);
    });
  }

  exportCheckupsExtendedStatistics(dateFrom, dateTo, checkupType, organizationId, terminalId, withoutRepeats) {
    return this.service.postJsonGetBlob('/statistics_checkup/checkups_extended/export', {
      date_from: formatApiDate(dateFrom),
      date_to: formatApiDate(dateTo),
      checkup_type: checkupType,
      organization: organizationId,
      terminal: terminalId,
      without_repeats: withoutRepeats,
    }).then(({blob}) => {
      downloadExcel(blob, `Осмотры ${formatDisplayDate(dateFrom)} - ${formatDisplayDate(dateTo)}`);
    });
  }

  getRiskGroup(dateFrom, dateTo, checkupType, organizationId, terminalId, riskGroupParams) {
    return this.service.postJsonGetJson('/statistics_checkup/risk_group', {
      date_from: formatApiDate(dateFrom),
      date_to: formatApiDate(dateTo),
      checkup_type: checkupType,
      organization: organizationId,
      terminal: terminalId,
      risk_group_params: riskGroupParams,
    });
  }

  exportRiskGroup(dateFrom, dateTo, checkupType, organizationId, terminalId, riskGroupParams) {
    return this.service.postJsonGetBlob('/statistics_checkup/risk_group/export', {
      date_from: formatApiDate(dateFrom),
      date_to: formatApiDate(dateTo),
      checkup_type: checkupType,
      organization: organizationId,
      terminal: terminalId,
      risk_group_params: riskGroupParams,
    }).then(({blob}) => {
      downloadExcel(blob, `Группа риска ${formatDisplayDate(dateFrom)} - ${formatDisplayDate(dateTo)}`);
    });
  }

  getAgeRiskGroup(dateFrom, dateTo, checkupType, organizationId, terminalId) {
    return this.service.postJsonGetJson('/statistics_checkup/age_risk_group', {
      date_from: formatApiDate(dateFrom),
      date_to: formatApiDate(dateTo),
      checkup_type: checkupType,
      organization: organizationId,
      terminal: terminalId,
    });
  }

  exportAgeRiskGroup(dateFrom, dateTo, checkupType, organizationId, terminalId) {
    return this.service.postJsonGetBlob('/statistics_checkup/age_risk_group/export', {
      date_from: formatApiDate(dateFrom),
      date_to: formatApiDate(dateTo),
      checkup_type: checkupType,
      organization: organizationId,
      terminal: terminalId,
    }).then(({blob}) => {
      downloadExcel(blob, `Группа риска за 55 лет ${formatDisplayDate(dateFrom)} - ${formatDisplayDate(dateTo)}`);
    });
  }

  getWorkingAndRestTime(dateFrom, dateTo, checkupType, organizationId, terminalId) {
    return this.service.postJsonGetJson('/statistics_checkup/working_and_rest_time', {
      date_from: formatApiDate(dateFrom),
      date_to: formatApiDate(dateTo),
      checkup_type: checkupType,
      organization: organizationId,
      terminal: terminalId,
    });
  }

  exportWorkingAndRestTime(dateFrom, dateTo, checkupType, organizationId, terminalId, isColored) {
    return this.service.postJsonGetBlob('/statistics_checkup/working_and_rest_time/export', {
      date_from: formatApiDate(dateFrom),
      date_to: formatApiDate(dateTo),
      checkup_type: checkupType,
      organization: organizationId,
      terminal: terminalId,
      is_colored: isColored
    }).then(({blob}) => {
      downloadExcel(blob, `Время работы и отдыха ${formatDisplayDate(dateFrom)} - ${formatDisplayDate(dateTo)}`);
    });
  }

  getBillingSumByOrganizations(organizations, dateFrom, dateTo) {
    return this.service.postJsonGetJson('/billing_sum/organizations', {
      date_from: dateFrom,
      date_to: dateTo,
      organizations: organizations || [],
    });
  }

  exportBillingSumByOrganizations(organizations, dateFrom, dateTo) {
    return this.service.postJsonGetBlob('/billing_sum/organizations/export', {
      date_from: dateFrom,
      date_to: dateTo,
      organizations: organizations || [],
    }).then(({ blob }) => {
      downloadExcel(blob, `Организации (Суммы) ${dateFrom} - ${dateTo}`);
    });
  }

  getBillingSumByTerminals(terminals, dateFrom, dateTo) {
    return this.service.postJsonGetJson('/billing_sum/terminals', {
      date_from: dateFrom,
      date_to: dateTo,
      terminals: terminals || [],
    });
  }

  exportBillingSumByTerminals(terminals, dateFrom, dateTo) {
    return this.service.postJsonGetBlob('/billing_sum/terminals/export', {
      date_from: dateFrom,
      date_to: dateTo,
      terminals: terminals || [],
    }).then(({ blob }) => {
      downloadExcel(blob, `Терминалы (Суммы) ${dateFrom} - ${dateTo}`);
    });
  }

  getBillingSumByDealers(dealers, dateFrom, dateTo) {
    return this.service.postJsonGetJson('/billing_sum/dealers', {
      date_from: dateFrom,
      date_to: dateTo,
      dealers: dealers || [],
    });
  }

  exportBillingSumByDealers(dealers, dateFrom, dateTo) {
    return this.service.postJsonGetBlob('/billing_sum/dealers/export', {
      date_from: dateFrom,
      date_to: dateTo,
      dealers: dealers || [],
    }).then(({ blob }) => {
      downloadExcel(blob, `Дилеры ${dateFrom} - ${dateTo}`);
    });
  }

  getUsageTechByOrganizations(organizations, dateFrom, dateTo, techInspectionTypes) {
    return this.service.postJsonGetJson('/usage_tech', {
      date_from: dateFrom,
      date_to: dateTo,
      tech_inspection_types: techInspectionTypes,
      organizations: organizations || [],
    });
  }

  exportUsageTechByOrganizations(organizations, dateFrom, dateTo, techInspectionTypes) {
    return this.service.postJsonGetBlob('/usage_tech/export', {
      date_from: dateFrom,
      date_to: dateTo,
      tech_inspection_types: techInspectionTypes,
      organizations: organizations || [],
    }).then(({ blob }) => {
      downloadExcel(blob, `Организации ${dateFrom} - ${dateTo}`);
    });
  }

  getUsageCheckupsByOrganizations(organizations, dateFrom, dateTo, checkupTypes) {
    return this.service.postJsonGetJson('/usage_checkup/organizations', {
      date_from: dateFrom,
      date_to: dateTo,
      checkup_types: checkupTypes,
      organizations: organizations || [],
    });
  }

  exportUsageCheckupsByOrganizations(organizations, dateFrom, dateTo, checkupTypes) {
    return this.service.postJsonGetBlob('/usage_checkup/organizations/export', {
      date_from: dateFrom,
      date_to: dateTo,
      checkup_types: checkupTypes,
      organizations: organizations || []
    }).then(({blob}) => {
      downloadExcel(blob, `Организации ${dateFrom} - ${dateTo}`);
    });
  }

  getUsageCheckupsByTerminals(terminals, dateFrom, dateTo, checkupTypes) {
    return this.service.postJsonGetJson('/usage_checkup/terminals', {
      date_from: dateFrom,
      date_to: dateTo,
      checkup_types: checkupTypes,
      terminals: terminals || []
    });
  }

  exportUsageCheckupsByTerminals(terminals, dateFrom, dateTo, checkupTypes) {
    return this.service.postJsonGetBlob('/usage_checkup/terminals/export', {
      date_from: dateFrom,
      date_to: dateTo,
      checkup_types: checkupTypes,
      terminals: terminals || []
    }).then(({blob}) => {
      downloadExcel(blob, `Терминалы ${dateFrom} - ${dateTo}`);
    });
  }

  getUsageCheckupsByDealers(dealers, dateFrom, dateTo, checkupTypes) {
    return this.service.postJsonGetJson('/usage_checkup/dealers', {
      date_from: dateFrom,
      date_to: dateTo,
      checkup_types: checkupTypes,
      dealers: dealers || []
    });
  }

  exportUsageCheckupsByDealers(dealers, dateFrom, dateTo, checkupTypes) {
    return this.service.postJsonGetBlob('/usage_checkup/dealers/export', {
      date_from: dateFrom,
      date_to: dateTo,
      checkup_types: checkupTypes,
      dealers: dealers || []
    }).then(({blob}) => {
      downloadExcel(blob, `Дилеры ${dateFrom} - ${dateTo}`);
    });
  }

  getHealthStatus(userId, dateFrom, dateTo, numberOfLastCheckups, reportType) {
    return this.service.postJsonGetJson('/health_status', {
      user_id: userId,
      date_from: dateFrom,
      date_to: dateTo,
      number_of_last_checkups: numberOfLastCheckups,
      report_type: reportType,
    });
  }

  clearMedicEcp(medic_id) {
    return this.service.postJsonGetJson(`/workers/${medic_id}/clear_ecp`);
  }

  getMedicEcpReport() {
    return this.service.getJson('/control/medic_ecp');
  }

  exportMedicEcpReport() {
    return this.service.getBlob('/control/medic_ecp/export?ts=' + Date.now()).then(({blob}) => {
      downloadExcel(blob, `Контроль ЭЦП ${formatDisplayDate(new Date())}`);
    });
  }

  getMedicEfficiencyDealers() {
    return this.service.getJson('/medic_efficiency/dealers');
  }

  getMedicEfficiencyReport(dateFrom, dateTo, dealerId) {
    return this.service.getJson('/medic_efficiency', {
      date_from: dateFrom,
      date_to: dateTo,
      dealer_id: dealerId,
    });
  }

  exportMedicEfficiencyReport(dateFrom, dateTo, dealerId) {
    return this.service.getBlob('/medic_efficiency/export', {
      date_from: dateFrom,
      date_to: dateTo,
      dealer_id: dealerId,
    }).then(({blob}) => {
      downloadExcel(blob, `Скорость работы медиков ${dateFrom} - ${dateTo}`);
    });
  }

  getMedicWorkloadMedOrganizations() {
    return this.service.getJson('/medic_workload/med_organizations');
  }

  getMedicWorkloadMedics(medOrganizationId) {
    return this.service.getJson('/medic_workload/medics', {
      med_organization_id: medOrganizationId
    });
  }

  getMedicWorkloadReport(medicId, dateFrom, dateTo) {
    return this.service.getJson('/medic_workload', {
      medic_id: medicId,
      date_from: dateFrom,
      date_to: dateTo,
    });
  }

  exportMedicWorkloadReport(medicId, dateFrom, dateTo) {
    return this.service.getBlob('/medic_workload/export', {
      medic_id: medicId,
      date_from: dateFrom,
      date_to: dateTo,
    }).then(({blob}) => {
      downloadExcel(blob, `Отчёт о нагрузке ${formatDisplayDate(dateFrom)} - ${formatDisplayDate(dateTo)}`);
    });
  }

  getTerminalVerificationReport() {
    return this.service.getJson('/control/terminal_verification');
  }

  getTerminalStatus() {
    return this.service.getJson('/control/terminal_status');
  }

  exportTerminalStatus() {
    return this.service.getBlob('/control/terminal_status/export').then(({ blob }) => {
      downloadExcel(blob, `Статус терминалов ${formatDisplayDate(new Date())}`);
    });
  }

  getTerminalChangelog(terminalId) {
    return this.service.getJson(`/terminals/${terminalId}/changelog`);
  }

  getUserChangelog(userId) {
    return this.service.getJson(`/users/${userId}/changelog`);
  }

  getWorkerChangelog(workerId) {
    return this.service.getJson(`/workers/${workerId}/changelog`);
  }

  getOrganizationChangelog(organizationId) {
    return this.service.getJson(`/organizations/${organizationId}/changelog`);
  }

  exportTerminalVerificationReport() {
    return this.service.getBlob('/control/terminal_verification/export').then(({ blob }) => {
      downloadExcel(blob, `Поверка оборудования ${formatDisplayDate(new Date())}`);
    });
  }

  getCheckupJournalsToSign(date) {
    return this.service.getJson('/signed_checkup_journals', {
      date: date,
    });
  }

  getEncodedCheckupJournal(date, organizationId, certificatePublicKey) {
    return this.service.getJson('/signed_checkup_journals/encoded_journal', {
      date: date,
      organization_id: organizationId,
      cert_public_key: certificatePublicKey,
    });
  }

  uploadSignedCheckupJournal(date, organizationId, journal, signature) {
    return this.service.postJsonGetJson('/signed_checkup_journals/upload_signed_journal', {
      date: date,
      organization_id: organizationId,
      journal: journal,
      signature: signature,
    });
  }

  getReceivedCheckupsReportByOrganisations(dateTimeFrom, dateTimeTo, period, organizations) {
    return this.service.postJsonGetJson('/received_checkups', {
      datetime_from: dateTimeFrom,
      datetime_to: dateTimeTo,
      period,
      organizations: organizations || [],
    });
  }

  exportReceivedCheckupsReportByOrganizations(dateTimeFrom, dateTimeTo, period, organizations) {
    return this.service.postJsonGetBlob('/received_checkups/export', {
      datetime_from: dateTimeFrom,
      datetime_to: dateTimeTo,
      period,
      organizations: organizations || [],
    }).then(({ blob }) => {
      const dtFrom = formatDisplayDateTime(dateTimeFrom);
      const dtTo = formatDisplayDateTime(dateTimeTo);
      downloadExcel(blob, `Отчёт о количестве пройденных осмотров за период с ${dtFrom} по ${dtTo}`);
    });
  }

  getReceivedCheckupsReportByTerminals(dateTimeFrom, dateTimeTo, period, terminals) {
    return this.service.postJsonGetJson('/received_checkups', {
      datetime_from: dateTimeFrom,
      datetime_to: dateTimeTo,
      period,
      terminals: terminals || [],
    });
  }

  exportReceivedCheckupsReportByTerminals(dateTimeFrom, dateTimeTo, period, terminals) {
    return this.service.postJsonGetBlob('/received_checkups/export', {
      datetime_from: dateTimeFrom,
      datetime_to: dateTimeTo,
      period,
      terminals: terminals || [],
    }).then(({ blob }) => {
      const dtFrom = formatDisplayDateTime(dateTimeFrom);
      const dtTo = formatDisplayDateTime(dateTimeTo);
      downloadExcel(blob, `Отчёт о количестве пройденных осмотров за период с ${dtFrom} по ${dtTo}`);
    });
  }

  getReceivedCheckupsReportByDealers(dateTimeFrom, dateTimeTo, period, dealers) {
    return this.service.postJsonGetJson('/received_checkups', {
      datetime_from: dateTimeFrom,
      datetime_to: dateTimeTo,
      period,
      dealers: dealers || [],
    });
  }

  exportReceivedCheckupsReportByDealers(dateTimeFrom, dateTimeTo, period, dealers) {
    return this.service.postJsonGetBlob('/received_checkups/export', {
      datetime_from: dateTimeFrom,
      datetime_to: dateTimeTo,
      period,
      dealers: dealers || [],
    }).then(({ blob }) => {
      const dtFrom = formatDisplayDateTime(dateTimeFrom);
      const dtTo = formatDisplayDateTime(dateTimeTo);
      downloadExcel(blob, `Отчёт о количестве пройденных осмотров за период с ${dtFrom} по ${dtTo}`);
    });
  }

  getUserInfo() {
    return this.service.getJson('/user_info');
  }

  getDocuments() {
    return this.service.getJson('/documents');
  }

  sendFeedback(name, phone, email, message) {
    return this.service.postJsonGetJson('/feedback', {
      name, phone, email, message,
    });
  }

  banWorker(workerIds, banReason) {
    return this.service.postJsonGetJson('/workers/ban', {
      worker_ids: workerIds,
      ban_reason: banReason,
    });
  }

  revokeWorkerBan(workerIds) {
    return this.service.postJsonGetJson('/workers/revoke_ban', {
      worker_ids: workerIds
    });
  }

  importCheckups(file) {
    return this.service.postMultipartGetJson('/checkups/import', {
      import_file: 'file:import_file'
    }, {
      import_file: file
    });
  }

  importUsers(organizationId, file) {
    return this.service.postMultipartGetJson('/users/import', {
      organization_id: organizationId,
      import_file: 'file:import_file'
    }, {
      import_file: file
    });
  }

  importVehicles(organizationId, file) {
    return this.service.postMultipartGetJson('/vehicles/import', {
      organization_id: organizationId,
      import_file: 'file:import_file'
    }, {
      import_file: file
    });
  }

  getAlcoholList() {
    return this.service.getJson('/organizations/sms_alcohol_report');
  }

  checkOrganizationInn(inn) {
    return this.service.getJson('/organizations/check_inn', {
      inn: inn
    });
  }

  regOrgInKaluga(orgId) {
    return this.service.getJson('/organizations/' + orgId + '/register_astral');
  }

  uploadUserPhoto(userId, photo) {
    return this.service.postMultipartGetJson('/users/' + userId + '/photo', {
      data: {
        attributes: {
          photo_file: 'file:user_photo'
        }
      }
    }, {
      user_photo: photo
    });
  }

  getWaybillPrintPage(waybillId, template) {
    return this.service.getText('/waybills/' + waybillId + '/print', null, {
        template,
        tz_offset: (new Date()).getTimezoneOffset()
    });
  }

  getWaybillPdf(waybillId, template) {
    return this.service.getBlob('/waybills/' + waybillId + '/pdf', {
        template,
        tz_offset: (new Date()).getTimezoneOffset()
    }).then(({blob}) => {
      downloadPdf(blob, `Путевой лист №${waybillId}`);
    });
  }

  getWaybillExcel(waybillId, template) {
    return this.service.getBlob('/waybills/' + waybillId + '/pdf', {
        template,
        tz_offset: (new Date()).getTimezoneOffset()
    }).then(({blob}) => {
      downloadExcel(blob, `Путевой лист №${waybillId}`);
    });
  }

  sendWaybill(waybillId) {
    return this.service.postJsonGetJson('/waybills/' + waybillId + '/resend');
  }

  getWaybillsMovementFormOrganizationsData() {
    return this.service.getJsonData('/waybills_movement/form/organizations');
  }

  getWaybillsMovementJournal({ periodFrom, periodTo, organizationId }) {
    return this.service.getBlob('/waybills_movement/export', {
      period_from: formatApiDateTime(periodFrom),
      period_to: formatApiDateTime(periodTo),
      organization_id: organizationId,
      tz_offset: (new Date()).getTimezoneOffset(),
    }).then(({ blob }) => downloadExcel(
      blob,
      `Журнал учета движения путевых листов ${formatDisplayDate(periodFrom)} - ${formatDisplayDate(periodTo)}`
    ));
  }

  getWaybillsMovementJournalPdf({ periodFrom, periodTo, organizationId }) {
    return this.service.getBlob('/waybills_movement/export_pdf', {
      period_from: formatApiDateTime(periodFrom),
      period_to: formatApiDateTime(periodTo),
      organization_id: organizationId,
      tz_offset: (new Date()).getTimezoneOffset(),
    }).then(({ blob }) => downloadPdf(
      blob,
      `Журнал учета движения путевых листов ${formatDisplayDate(periodFrom)} - ${formatDisplayDate(periodTo)}`
    ));
  }

  getEwaybillPrintPage(ewaybillId, template) {
    return this.service.getText('/ewaybills/' + ewaybillId + '/print', null, {
        template,
        tz_offset: (new Date()).getTimezoneOffset()
    });
  }

  getEncodedEwaybillXml(ewaybillId, certPublicKey) {
    return this.service.getJson('/signed_ewaybill_astral/get_xml_ewaybill', {
      ewaybill_id: ewaybillId,
      cert_public_key: certPublicKey,
    });
  }

  uploadSignedEwaybillXml(ewaybillId, journal, signature) {
    return this.service.postJsonGetJson('/signed_ewaybill_astral/upload_signed_xml', {
      ewaybill_id: ewaybillId,
      journal: journal,
      signature: signature
    });
  }

  signEWaybill(ewaybillId) {
    return this.service.postJsonGetJson('/ewaybills/' + ewaybillId + '/sign');
  }

  unsignEWaybill(ewaybillId) {
    return this.service.postJsonGetJson('/ewaybills/' + ewaybillId + '/unsign');
  }

  createTechInspection(ewaybillId) {
    return this.service.postJsonGetJson('/ewaybills/' + ewaybillId + '/create_tech_inspection');
  }

  getTechInspections({dateFrom, dateTo, name, driverName, carLicensePlate, organizationId, pageSize, sort}) {
    const filters = {};
    if (dateFrom) filters['filter[date_from]'] = dateFrom;
    if (dateTo) filters['filter[date_to]'] = dateTo;
    if (name) filters['filter[name]'] = name;
    if (driverName) filters['filter[driver_name]'] = driverName;
    if (carLicensePlate) filters['filter[car_license_plate]'] = carLicensePlate;
    if (organizationId) filters['filter[organization_id]'] = organizationId;
    if (pageSize) filters['page[size]'] = pageSize;
    if (sort) filters['sort'] = sort;
    return this.service.getJson('/tech_inspections', filters);
  }

  getTechInspectionsUpdates({createdFrom, updatedFrom, vehicleId, driverId, organizationId}) {
    const filters = {
      created_from: createdFrom,
      updated_from: updatedFrom
    };
    if (vehicleId) filters['filter[vehicle_id]'] = vehicleId;
    if (driverId) filters['filter[driver_id]'] = driverId;
    if (organizationId) filters['filter[organization_id]'] = organizationId;
    return this.service.getJson('/tech_inspections/updates', filters);
  }

  getTechInspectionJournalFormOrganizationsData() {
    return this.service.getJsonData('/tech_inspection_journals/form/organizations');
  }

  getTechInspectionJournalFormInspectionPointsData(organizationId) {
    return this.service.getJsonData('/tech_inspection_journals/form/inspection_points', {
      organization_id: organizationId,
    });
  }

  getTechInspectionJournal({
    periodFrom, periodTo, organizationId, inspectionPointId, journalType
  }) {
    return this.service.getBlob('/tech_inspection_journals/export', {
      period_from: formatApiDateTime(periodFrom),
      period_to: formatApiDateTime(periodTo),
      organization_id: organizationId,
      inspection_point_id: inspectionPointId,
      type: journalType,
      tz_offset: (new Date()).getTimezoneOffset(),
    }).then(({ blob }) => downloadDocx(
      blob,
      `Журнал регистрации результатов ТО ${formatDisplayDate(periodFrom)} - ${formatDisplayDate(periodTo)}`,
    ));
  }

  getTechInspectionJournalPdf({
    periodFrom, periodTo, organizationId, inspectionPointId, journalType,
  }) {
    return this.service.getBlob('/tech_inspection_journals/export_pdf', {
      period_from: formatApiDateTime(periodFrom),
      period_to: formatApiDateTime(periodTo),
      organization_id: organizationId,
      inspection_point_id: inspectionPointId,
      type: journalType,
      tz_offset: (new Date()).getTimezoneOffset(),
    }).then(({ blob }) => downloadPdf(
      blob,
      `Журнал регистрации результатов ТО ${formatDisplayDate(periodFrom)} - ${formatDisplayDate(periodTo)}`,
    ));
  }

  getEncodedTechInspectionXml(techInspectionId, certPublicKey) {
    return this.service.getJson('/signed_tech_inspection_astral/get_xml_tech_inspection', {
      tech_inspection_id: techInspectionId,
      cert_public_key: certPublicKey,
    });
  }

  uploadSignedTechInspectionXml(techInspectionId, journal, signature) {
    return this.service.postJsonGetJson('/signed_tech_inspection_astral/upload_signed_xml', {
      tech_inspection_id: techInspectionId,
      journal: journal,
      signature: signature
    });
  }

  getEncodedTechInspectionXmlOdometr(techInspectionId, certPublicKey) {
    return this.service.getJson('/signed_odometr_astral/get_xml_odometr', {
      tech_inspection_id: techInspectionId,
      cert_public_key: certPublicKey,
    });
  }

  uploadSignedTechInspectionXmlOdometr(techInspectionId, journal, signature) {
    return this.service.postJsonGetJson('/signed_odometr_astral/upload_signed_xml', {
      tech_inspection_id: techInspectionId,
      journal: journal,
      signature: signature
    });
  }

  getEncodedPostTechInspectionXml(techInspectionId, certPublicKey) {
    return this.service.getJson('/signed_tech_inspection_astral/get_xml_tech_inspection', {
      tech_inspection_id: techInspectionId,
      cert_public_key: certPublicKey,
    });
  }

  uploadSignedPostTechInspectionXml(techInspectionId, journal, signature) {
    return this.service.postJsonGetJson('/signed_tech_inspection_astral/upload_signed_xml', {
      tech_inspection_id: techInspectionId,
      journal: journal,
      signature: signature
    });
  }

  getEncodedPostTechInspectionXmlOdometr(techInspectionId, certPublicKey) {
    return this.service.getJson('/signed_odometr_astral/get_xml_odometr', {
      tech_inspection_id: techInspectionId,
      cert_public_key: certPublicKey,
    });
  }

  uploadSignedPostTechInspectionXmlOdometr(techInspectionId, journal, signature) {
    return this.service.postJsonGetJson('/signed_odometr_astral/upload_signed_xml', {
      tech_inspection_id: techInspectionId,
      journal: journal,
      signature: signature
    });
  }

  getCheckupJournalFormOrganizationsData() {
    return this.service.getJsonData('/checkup_journals/form/organizations');
  }

  getCheckupJournal({ periodFrom, periodTo, organizationId }) {
    return this.service.getBlob('/checkup_journals/export', {
      period_from: formatApiDateTime(periodFrom),
      period_to: formatApiDateTime(periodTo),
      organization_id: organizationId,
    });
  }

  getCheckupJournalPdf({ periodFrom, periodTo, organizationId }) {
    return this.service.getBlob('/checkup_journals/export_pdf', {
      period_from: formatApiDateTime(periodFrom),
      period_to: formatApiDateTime(periodTo),
      organization_id: organizationId,
    });
  }

  getCheckupJournalXlsx({ periodFrom, periodTo, organizationId }) {
    return this.service.getBlob('/checkup_journals/export_xlsx', {
      period_from: formatApiDateTime(periodFrom),
      period_to: formatApiDateTime(periodTo),
      organization_id: organizationId,
    });
  }

  getActiveUsers() {
    return this.service.getJson('/active_users');
  }

  getActiveTerminals() {
    return this.service.getJson('/active_terminals');
  }

  sendPasswordResetCode(login) {
    return this.service.postJsonGetJson('/auth/send_password_reset_code', { login });
  }

  checkPasswordResetCode(token, code) {
    return this.service.postJsonGetJson('/auth/check_password_reset_code', { token, code });
  }

  resetPassword(token, code, password, confirm) {
    return this.service.postJsonGetJson('/auth/reset_password', {
      token, code, password, confirm,
    });
  }

  changePassword(oldPassword, newPassword, confirm) {
    return this.service.postJsonGetJson('/auth/change_password', {
      old_password: oldPassword,
      new_password: newPassword,
      confirm,
    });
  }

  abort() {
    this.service.abort();
  }
}

export const createTelemedicService = () => new TelemedicService(getAuthToken());

export default TelemedicService;
